import React, { useState, useEffect } from 'react'
import '../styles/DarkTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import OrganizationExecutiveService from '../services/OrganizationExecutiveService';
import { toast } from "react-toastify";

export default function OrganizationExecutiveList() {

    const organizationService = new OrganizationExecutiveService()

    const [organizationExecutives, setOrganizationExecutives] = useState([]);

    useEffect(() => {

        organizationService.getAllOrganizationExecutives().then(result => {

            //console.log(result)
            if (result.data.success) {

                setOrganizationExecutives(result.data.data);
            }

        }).catch(error => {
            toast.error(error.response.data.data.message);
        })

    }, []);

    return (

        <div className="dark-tb-main">

            <div className="container-fluid text-light">

                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="4">Organizasyon Yürütme Kurulu</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color dark-tb-body-text" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREV YERİ</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREVİ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {organizationExecutives.map((item, index) => (
                            <tr key={item.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className='dark-tb-body-text'>{item.name}</td>
                                <td className='dark-tb-body-text'>{item.dutyLocation}</td>
                                <td className='dark-tb-body-text'>{item.dutyDescription}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
        
    )
}
