const HostAndPortInfo = Object.freeze({

    //HOSTNAME: "localhost",
    //HOSTNAME: "192.168.80.74", //merve pc
    //HOSTNAME: "192.168.80.104", //derya pc
    HOSTNAME: "azty.org", //server
    PORT: "8080",
    PROTOCOL: "https"
});

export default HostAndPortInfo;
