import React, { useState, useRef } from 'react';
import '../../styles/AskQuestion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import QuestionService from '../../services/QuestionService';
import { toast } from "react-toastify";
import '../../styles/Loading.css';


export default function AskQuestion() {

    const questionService = new QuestionService()

    const fileInputRef = useRef(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImageFiles, setSelectedImageFiles] = useState([]);

    const [loading, setLoading] = useState(false); // Yüklenme durumu

    const handleClick = () => {
        // Butona tıklandığında dosya seçme penceresini aç
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (e) => {

        const files = Array.from(e.target.files);

        if (files.length > 0) {
            setSelectedImageFiles((prevFiles) => [...prevFiles, ...files]); // Önceki dosyaları koruyarak yeni dosyaları ekle
        }
    };

    const removeImage = (indexToRemove) => {
        setSelectedImageFiles((prevFiles) =>
            prevFiles.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleSubmitQuestion = () => {

        setLoading(true)
        let formData = new FormData();
        formData.append('title', title);
        formData.append("description", description);

        // Her bir dosyayı ayrı ayrı ekley
        selectedImageFiles.forEach(file => {
            formData.append('files', file);
        });

        questionService.addQuestion(formData).then(result => {

            if (result.data.success === true) {

                setLoading(false)

                toast.success("Sorunuz sisteme başarıyla kaydedildi. En kısa sürede dönüş sağlanacaktır.");

                setTitle('')
                setDescription('');
                setSelectedImageFiles([]);
            }
        }).catch(error => {

            setLoading(false)
            toast.error(error.response.data.data.message);

        })

    }


    return (
        <div className='ask-us-question-container'>

            {loading === true &&
                <div className="loading-container">
                    <div className="loading-icon"></div> {/* Loading ikonu */}
                    <p>Sorunuz gönderiliyor...</p>
                </div>
            }

            {loading === false && (

                <><>

                    <textarea
                        type="text"
                        placeholder="Soru başlığınızı yazınız"
                        className='ask-us-title-input'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)} />


                    <textarea
                        type="text"
                        placeholder="Sorunuzu açıklayınız"
                        className='ask-us-body-input'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />

                    <p> Sorunuzu göndererek, sorunuzun kabul edilmesi halinde cevabının herkese açık olarak <br />paylaşılabileceğini kabul etmiş olursunuz. </p>
                    <p>İhtiyaç halinde sorunuzu görseller ile destekleyebilirsiniz.</p>



                    {selectedImageFiles.length > 0 && (
                        <div className="selected-files-container">
                            {selectedImageFiles.map((file, index) => (
                                <div key={index} className="image-preview-container">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`preview-${index}`}
                                        className="image-preview" />
                                    <div className="image-info">
                                        <span className="image-name">{file.name}</span>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className="remove-icon"
                                            onClick={() => removeImage(index)} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                </><div className='ask-usb-btn-container'>

                        <button className="ask-us-img-submit-button" onClick={handleClick}>
                            <FontAwesomeIcon icon={faImage} style={{ color: "white" }} />
                            Görsel Ekle

                            <input
                                type="file"
                                multiple // Çoklu dosya seçimine izin ver
                                accept="image/*"
                                onChange={handleImageChange}
                                ref={fileInputRef}
                                style={{ display: 'none' }} // Input'u gizle
                            />
                        </button>

                        <button className="ask-us-submit-button" onClick={handleSubmitQuestion}>
                            <FontAwesomeIcon icon={faPaperPlane} style={{ color: "white" }} />
                            GÖNDER
                        </button>
                    </div></>
            )}



        </div>

    )
}
