import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import HomeDashboard from './dashboards/HomeDashboard';
import ExamDashboard from './dashboards/ExamDashboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className="App">
      <ToastContainer position="bottom-right" autoClose={3000} />

      <Router>
        <Routes>
          {/* / rotasında herhangi bir şey açılmadan /home yönlendirmesi yapılır */}
          <Route path="/" element={<Navigate to="/home" />} />
          <Route exact path="/home/*" element={<HomeDashboard />} />
          <Route exact path="/exam/*" element={<ExamDashboard />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
