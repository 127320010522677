import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/AskUsHome.css';
import AskQuestion from './AskQuestion';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import YourQuestions from './YourQuestions';

export default function AskUsHome() {

    const [selectedMenu, setSelectedMenu] = useState('Soru Sor');

    const renderContent = () => {
        switch (selectedMenu) {
            case 'Soru Sor':
                return <div><AskQuestion /></div>;
            case 'Sorularınız':
                return <div><YourQuestions /></div>;
            case 'Sık Sorulan Sorular':
                return <div><FrequentlyAskedQuestions /></div>;
            default:
                return <div></div>;
        }
    };

    return (
        <div className="ask-us-bg-img d-flex">

            {/* Menü kısmı */}
            <div className="p-3 ask-us-menu-container">

                <div className='ask-us-menu' >
                    <ul className="nav flex-column">
                        <li className="nav-item">

                            <span className="icon-wrapper">
                                <i className="menu-icon ask-question-icon me-3" />
                            </span>
                            <button
                                className={`nav-link btn ${selectedMenu === 'Soru Sor' ? 'active' : 'transparent'} ask-us-menu-text`}
                                onClick={() => setSelectedMenu('Soru Sor')}
                            >
                                Soru Sor
                            </button>
                        </li>
                        <li className="nav-item mt-2">

                            <span className="icon-wrapper">
                                <i className="menu-icon your-question-icon me-3" />
                            </span>
                            <button
                                className={`nav-link btn ${selectedMenu === 'Sorularınız' ? 'active' : 'transparent'} ask-us-menu-text`}
                                onClick={() => setSelectedMenu('Sorularınız')}
                            >

                                Sorularınız
                            </button>
                        </li>
                        <li className="nav-item mt-2">

                            <span className="icon-wrapper">
                                <i className="menu-icon frequently-ask-question-icon me-3" />
                            </span>
                            <button
                                className={`nav-link btn ${selectedMenu === 'Sık Sorulan Sorular' ? 'active' : 'transparent'} ask-us-menu-text`}
                                onClick={() => setSelectedMenu('Sık Sorulan Sorular')}
                            >
                                Sık Sorulan Sorular
                            </button>
                        </li>
                    </ul>
                </div>

            </div>

            {/* İçerik kısmı */}
            <div className="ask-us-page">
                <div className="ask-us-page-card">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}
