import React, { useState } from 'react'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import '../styles/TeamRegistration.css';
import TeamService from '../services/TeamService'


export default function TeamRegistration() {

    const teamService = new TeamService();
    const navigate = useNavigate();

    const [firstStudent, setFirstStudent] = useState({ firstname: '', lastname: '', identityNo: '' });
    const [secondStudent, setSecondStudent] = useState({ firstname: '', lastname: '', identityNo: '' });

    const [studentError, setStudentError] = useState({});


    const isValidTc = (tc) => {
        // TC numarası 11 haneli olmalı ve ilk karakter 0 olamaz
        if (!/^[1-9][0-9]{10}$/.test(tc)) return false;

        let sumOdd = 0;  // 1, 3, 5, 7, 9. basamakların toplamı
        let sumEven = 0; // 2, 4, 6, 8. basamakların toplamı
        let sumFirst10 = 0;  // İlk 10 basamağın toplamı

        // Basamakları dolaşarak toplamları hesapla
        for (let i = 0; i < 9; i++) {
            const digit = parseInt(tc[i]);
            sumFirst10 += digit;
            if (i % 2 === 0) {
                sumOdd += digit;  // Tek basamaklar (1, 3, 5...)
            } else {
                sumEven += digit; // Çift basamaklar (2, 4, 6...)
            }
        }

        const digit10 = ((sumOdd * 7) - sumEven) % 10;  // 10. hane
        sumFirst10 += parseInt(tc[9]);  // 10. basamağı da ilk 10 toplamına ekle
        const digit11 = sumFirst10 % 10;  // 11. hane

        // 10. ve 11. basamaklar kontrol ediliyor
        return digit10 === parseInt(tc[9]) && digit11 === parseInt(tc[10]);
    };

    const handleSubmit = () => {

        setStudentError({})

        if (!firstStudent.firstname || !firstStudent.lastname || !firstStudent.identityNo) {

            setStudentError({ student: 'Öğrenci-1 adı, soyadı ve TC numarası gerekli.' });
            return;
        }


        if (!isValidTc(firstStudent.identityNo)) {
            setStudentError({ student: 'Öğrenci-1 için geçerli bir TC numarası giriniz.' });
            return;
        }


        if (!secondStudent.firstname || !secondStudent.lastname || !secondStudent.identityNo) {

            setStudentError({ student: 'Öğrenci-2 adı, soyadı ve TC numarası gerekli.' });
            return;
        }

        if (!isValidTc(secondStudent.identityNo)) {
            setStudentError({ student: 'Öğrenci-2 için geçerli bir TC numarası giriniz.' });
            return;
        }

        // Create data objects for both students
        const firstStudentData = {
            firstName: firstStudent.firstname,
            lastName: firstStudent.lastname,
            identityNo: firstStudent.identityNo,
            userId: localStorage.getItem("savedUserId")
        };

        const secondStudentData = {
            firstName: secondStudent.firstname,
            lastName: secondStudent.lastname,
            identityNo: secondStudent.identityNo,
            userId: localStorage.getItem("savedUserId")
        };

        // Combine both students into an array
        const studentsData = [firstStudentData, secondStudentData];

        // Send the array to the API
        teamService.addTeam(studentsData)
            .then(result => {
                if (result.data.success) {
                    toast.success('Her iki öğrenci başarıyla kaydedildi.');

                    // Clear the form after 1 seconds
                    setTimeout(() => {
                        setFirstStudent({ firstname: '', lastname: '', identityNo: '' }); // Reset first student state
                        setSecondStudent({ firstname: '', lastname: '', identityNo: '' }); // Reset second student state

                        navigate('/home/signIn');
                        localStorage.setItem("savedUserId", "0");
                    }, 1000);

                } else {
                    toast.error('Öğrencilerin kaydı başarısız oldu.');
                }
            })
            .catch(error => {
                toast.error(error.response?.data?.data?.message || error.message);
            });
    };


    return (
        <div className='team-register-bg'>
            <div className='team-register-container'>
                <div className='team-register-desc'>
                    <p>Takım Bilgilerini Gir</p>
                    <p>Takımını oluştur ve öğrenci bilgilerini doğru girdiğinden emin ol.</p>
                </div>


                <div className='team-register-form'>
                    {/* Öğrenci Formu */}
                    <form className='team-form'>
                        <div className="mb-3">
                            <label htmlFor="studentName" className="team-form-label">Öğrenci-1 Ad</label>
                            <input
                                type="text"
                                id="studentName"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => setFirstStudent({ ...firstStudent, firstname: e.target.value })}
                                value={firstStudent.firstname}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="studentName" className="team-form-label">Öğrenci-1 Soyad</label>
                            <input
                                type="text"
                                id="studentLastname"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => setFirstStudent({ ...firstStudent, lastname: e.target.value })}
                                value={firstStudent.lastname}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="studentTc" className="team-form-label">Öğrenci-1 TC</label>
                            <input
                                type="number"
                                id="studentTc"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length <= 11) {
                                        setFirstStudent({ ...firstStudent, identityNo: value })
                                    }
                                }}
                                value={firstStudent.identityNo}
                            />
                        </div>

                        <div className="mb-3 ">
                            <label htmlFor="studentName" className="team-form-label">Öğrenci-2 Ad</label>
                            <input
                                type="text"
                                id="studentName"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => setSecondStudent({ ...secondStudent, firstname: e.target.value })}
                                value={secondStudent.firstname}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="studentName" className="team-form-label">Öğrenci-2 Soyad</label>
                            <input
                                type="text"
                                id="studentLastname"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => setSecondStudent({ ...secondStudent, lastname: e.target.value })}
                                value={secondStudent.lastname}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="studentTc" className="team-form-label">Öğrenci-2 TC</label>
                            <input
                                type="number"
                                id="studentTc"
                                className="form-control team-form-input"
                                required
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value.length <= 11) {
                                        setSecondStudent({ ...secondStudent, identityNo: value })
                                    }
                                }}
                                value={secondStudent.identityNo}
                            />
                        </div>

                        {studentError.student && <small className="text-danger">{studentError.student}</small>}



                    </form>

                    <button type="button" className="team-register-btn" onClick={handleSubmit}>
                        Kayıt Et
                    </button>
                </div>

            </div>
        </div>
    )
}
