import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class TechnicalAdvisorService {

  getAllTechnicalAdvisors() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/technical-advisors`,
    })
  }

  saveTechnicalAdvisor(saveDatas) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/technical-advisors`,
      data: saveDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  updateTechnicalAdvisor(id, updateDatas) { 

    return axios({
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/technical-advisors/${id}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') },
      data: updateDatas
    })
  }

  deleteTechnicalAdvisor(id) { 

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/technical-advisors/${id}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') }
    })
  }

}
