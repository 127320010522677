import React, { useState, useEffect } from 'react'

import Exam from '../pages/Exam';

export default function ExamDashboard() {

    return (

        <div>

            <div className="col-12" >
                <Exam/>
            </div>

        </div>
    )
}