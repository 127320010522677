import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class InstitutionService {

  getAllInstitutions() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/institutions`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }

  saveInstitution(saveDatas) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/institutions`,
      data: saveDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  updateInstitution(id,updateDatas) {

    return axios({
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/institutions/${id}`,
      data: updateDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  deleteInstitution(id) {

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/institutions/${id}`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

}
