import React, { useState, useRef, useEffect } from 'react';
import '../../styles/YourQuestion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Loading.css';
import { toast } from "react-toastify";
import QuestionService from '../../services/QuestionService';
import HostAndPortInfo from "../../enums/EnHostandPortInfo";

const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function YourQuestions() {

  const questionService = new QuestionService()


  const userFirstName = sessionStorage.getItem("firstName");
  const userId = sessionStorage.getItem("userId");
  const userLastName = sessionStorage.getItem("lastName");

  const [showDetail, setShowDetail] = useState(false);
  const [menuOpenId, setMenuOpenId] = useState(null); // Hangi menünün açık olduğunu izler
  const menuRefs = useRef({}); // Tüm menü referanslarını izleyen obje


  const [loading, setLoading] = useState(false); // Yüklenme durumu
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null); //detayını görmek istediği nesne için

  const [refresh, setRefresh] = useState(false); // Durum değişkeni



  // Boş bir yere tıklandığında menüyü kapatmak için useEffect
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Eğer tıklanan alan açık olan menünün dışındaysa menüyü kapat
      if (menuOpenId !== null && menuRefs.current[menuOpenId] && !menuRefs.current[menuOpenId].contains(event.target)) {
        setMenuOpenId(null);
      }
    };

    // Document üzerindeki tıklama olaylarını dinle
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Temizlik işlemi: Event listener'ı kaldır
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpenId]);

  const toggleMenu = (id) => {
    if (menuOpenId === id) {
      setMenuOpenId(null); // Aynı menüye tıklanırsa kapat
    } else {
      setMenuOpenId(id); // Farklı menüye tıklanırsa o menüyü aç
    }
  };


  useEffect(() => {

    setLoading(true)

    questionService.getQuestionByUserId(userId).then(result => {

      if (result.data.success) {
        setQuestions(result.data.data);
      }
    }).catch(error => {

     // toast.error(error.response.data.data.message);
    }).finally(() => {

      setLoading(false);
    });



  }, [refresh])

  function getDaysAgo(createDate) {
    const createdDate = new Date(createDate); // Sorunun oluşturulma tarihi
    const currentDate = new Date(); // Şu anki tarih

    // Günleri sadece tarih kısmı üzerinden karşılaştır
    const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    // Gün farkını hesapla
    const timeDiff = currentDateOnly - createdDateOnly;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Gün farkını döndür (0 ise "Bugün", 1 ise "Dün", diğer durumlar için "X gün önce")
    if (daysDiff === 0) {
      return 'Bugün';
    } else if (daysDiff === 1) {
      return 'Dün';
    } else {
      return `${daysDiff} gün önce`;
    }
  }


  const handleDetailClick = (question) => {
    setShowDetail(!showDetail)
    setSelectedQuestion(question); //deatylı gormek istediği nesne  set edilir
  };


  const handleReturnDetailClick = () => {
    setShowDetail(!showDetail)
    setSelectedQuestion(null); //deatylı gormek istediği nesne  set edilir
  };

  const handleDelete = (id) => {

    questionService.deleteQuestionByUser(id).then(result => {

      if (result.status === 200) {

        toast.success("Sorunuz başarıyla silindi.");
        setRefresh(prev => !prev); // refresh durumunu değiştir

      }

    }).catch(error => {

      const fullMessage = error.response.data.data.message;
      const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
      const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
      toast.error(message);

    }).finally(() => {
      // Menüyü kapat
      setMenuOpenId(null); // Menüyü kapat
    })
  }


  return (
    <div className='your-question-container'>

      {loading === true &&
        <div className="loading-container">
          <div className="loading-icon"></div> {/* Loading ikonu */}
          <p>Veriler yükleniyor...</p>
        </div>
      }

      {showDetail === false && loading === false && (

        questions.map((question, index) => (
          <div className='your-question-user-container' key={index}>
            <div className='user-question-top'>
              <div className='user-question-profile'>
                <img
                  src={`${process.env.PUBLIC_URL}/images/user-profile.png`}
                  alt="Profile"
                />
                <ul>
                  <li>{userFirstName + " " + userLastName}</li>
                  <li>{getDaysAgo(question.createDate)}</li>
                </ul>
              </div>

              <div className="question-menu-container" ref={el => menuRefs.current[question.id] = el}>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="question-menu-icon"
                  onClick={() => toggleMenu(question.id)} // Her soru için farklı ID
                />
                {menuOpenId === question.id && (
                  <div className="question-dropdown-menu">
                    <ul>
                      <li onClick={() => handleDelete(question.id)}>Sil</li>
                    </ul>
                  </div>
                )}
              </div>

            </div>

            <div className='user-question-mid'>
              <p>{question.title}</p>
              <p>{question.description}</p>
            </div>

            <div className='user-question-bottom'>
              {question.response === null ? (
                <button onClick={() => handleDetailClick(question)}>
                  Cevap Bekleniyor...
                </button>
              ) : (
                <button onClick={() => handleDetailClick(question)}>
                  Yanıtı Gör
                </button>
              )}
            </div>

          </div>
        ))
      )}

      {showDetail === true && selectedQuestion && (
        <>
          <div className='your-question-user-container'>
            <div className='user-question-detail'>
              <p>{selectedQuestion.title}</p>
              <p>{selectedQuestion.description}</p>
            </div>

            <div className='user-question-detail-imgs'>
              {selectedQuestion.questionImagePaths.map((imagePath, index) => (
                <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="question image" />
              ))}
            </div>
          </div>

          {selectedQuestion.response && (
            <div className='your-question-user-container'>
              <div className='admin-question-response'>
                <p>CEVAP: {selectedQuestion.response}</p>
              </div>

              <div className='user-question-detail-imgs'>
                {selectedQuestion.responseImagePaths.map((imagePath, index) => (
                  <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="response image" />
                ))}
              </div>
            </div>
          )}

          <button id='return-question-button' onClick={() => handleReturnDetailClick()}>
            Sorularınıza Geri Dönün
          </button>
        </>
      )}


    </div>
  )
}
