import React, { useState, useEffect } from 'react'
import '../styles/DarkTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TechnicalAdvisorService from '../services/TechnicalAdvisorService';
import { toast } from "react-toastify";

export default function TechnicalAdvisorsList() {

    const technicalAdvisorService = new TechnicalAdvisorService()

    const [technicalAdvisors, setTechnicalAdvisor] = useState([]);

    useEffect(() => {

        technicalAdvisorService.getAllTechnicalAdvisors().then(result => {

            // console.log(result)
            if (result.data.success) {
                setTechnicalAdvisor(result.data.data);
            }

        }).catch(error => {
            toast.error("Teknik danışmanları listelerken bir sorun oluştu!");
        })

    }, []);

    return (

        <div className="dark-tb-main">

            <div className="container-fluid text-light">

                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="5">TEKNİK DANIŞMAN LİSTESİ</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color dark-tb-body-text" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">UNVANI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREV YERİ</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">YARIŞMA GÖREV TANIMI</th>
                        </tr>
                    </thead>
                    <tbody className='dark-tb-body-text'>
                        {technicalAdvisors.map((item, index) => (
                            <tr key={item.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{item.name}</td>
                                <td className="dark-tb-body-text">{item.title}</td>
                                <td className="dark-tb-body-text">{item.dutyLocation}</td>
                                <td className="dark-tb-body-text">{item.dutyTaskDescription}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>

    )
}
