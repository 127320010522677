import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from "react-router-dom";
import '../styles/Navbar.css';

export default function Navbar() {

    const navigate = useNavigate();

    const [isOrganizasyonOpen, setIsOrganizasyonOpen] = useState(false);
    const [isArsivOpen, setIsArsivOpen] = useState(false);
    const [isUserOpen, setIsUserOpen] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const userType = sessionStorage.getItem("role");
    const userFirstName = sessionStorage.getItem("firstName");
    const userLastName = sessionStorage.getItem("lastName");


    useEffect(() => {

        // Close menu when clicking outside
        const handleClickOutside = (event) => {
            // Check if the click was outside the menu
            if (event.target.closest('.organizasyon-menu') === null &&
                event.target.closest('.arsiv-menu') === null &&
                event.target.closest('.navbar-container') === null &&
                event.target.closest('.user-menu') === null) {

                setIsOrganizasyonOpen(false);
                setIsArsivOpen(false);
                setIsUserOpen(false);

                // Remove 'responsive' class from the main-nav if it's present
                const mainNav = document.getElementById("main-nav");
                if (mainNav && mainNav.classList.contains("responsive")) {
                    mainNav.classList.remove("responsive");
                }

            }
        };

        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    const toggleOrganizasyon = (event) => {
        // Prevent the click event from bubbling up to the document
        event.stopPropagation();
        setIsOrganizasyonOpen(!isOrganizasyonOpen);
        setIsArsivOpen(false);
        setIsUserOpen(false);
        setActiveButton('organizasyon');
    };

    const toggleArsiv = (event) => {
        // Prevent the click event from bubbling up to the document
        event.stopPropagation();
        setIsArsivOpen(!isArsivOpen);
        setIsOrganizasyonOpen(false);
        setIsUserOpen(false);
        setActiveButton('arsiv');
    };

    const toggleUser = (event) => {
        // Prevent the click event from bubbling up to the document
        event.stopPropagation();
        setIsUserOpen(!isUserOpen);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        setActiveButton('user');
    };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);

    };

    const handleContextMenu = (e, path) => {
        e.preventDefault(); // Sağ tıklama menüsünü varsayılan olarak açma
        window.open(path, '_blank'); // Sağ tıklamada yeni sekmede açma
    };

    const handleHomeClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        setActiveButton('anasayfa')
        navigate('/home');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleLogoClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        setActiveButton('anasayfa')
        navigate('/home');
    };


    const handleHayrettinatmacaClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        handleButtonClick('atmaca')
        window.open('https://hayrettinatmaca.org.tr/', '_blank');
        displayMenu(); // Menü kapatma işlemi telefon için

    };

    const handleAnnouncementClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/announcement');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleTechnicAdvisorListClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/technic-advisor-list');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleOrganizationExecutiveListClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/organization-executive-list');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    // Arşiv menüsündeki öğeler için menü kapanması
    const handleArchiveHome2024Click = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/archiveHome2024');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    // Arşiv menüsündeki öğeler için menü kapanması
    const handleArchiveHome2023Click = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/archiveHome2023');
        displayMenu(); // Menü kapatma işlemi telefon için
    };


    // User menüsü için (ROLE_USER) öğeleri
    const handleExamClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/user-home');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleAskUsClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/ask-us-home');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleTeamClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/my-team'); // Burada key değerini gönderiyoruz
        displayMenu(); // Menü kapatma işlemi telefon için
        window.location.reload();

    };


    const handleExamResultClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/exam-result');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    // Admin menüsündeki öğeler
    const handleManageAnnouncementClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manageAnnouncement');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManageInstitutionsClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manage-institutions');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const listAllUsersClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/list-all-users');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManageTechnicAdvisorClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manage-technic-advisor');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManageOrganizationExecutiveClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manage-organization-executive');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    // İletişim menüsü
    const handleContactClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        setActiveButton('iletisim');
        navigate('/home/contact');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManagePresentationClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/managePresentation');
        displayMenu(); // Menü kapatma işlemi telefon için
    };


    const handleAccommodationClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/accomodation');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManageAccommodationClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manage-accomodation');
        displayMenu(); // Menü kapatma işlemi telefon için
    };

    const handleManageAskUsClick = () => {
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home/manageAskUs');
        displayMenu(); // Menü kapatma işlemi telefon için
    };


    const handleLogOut = () => {
        sessionStorage.clear(); // Oturum bilgilerini temizle
        setIsUserOpen(false);
        setIsArsivOpen(false);
        setIsOrganizasyonOpen(false);
        navigate('/home');
        displayMenu(); // Menü kapatma işlemi telefon için

    };


    const displayMenu = () => {
        var link = document.getElementById("main-nav");
        link.classList.toggle("responsive");
    };


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

            <div id="main-nav" className="navbar-container">

                <div className="navbar-logo" onClick={handleLogoClick}>
                    <img src={`${process.env.PUBLIC_URL}/images/RoboRallyLogo.png`} alt={`RoboRally Logo`} />

                </div>

                <div className="navbar-menu" >


                    <li className="main-nav_item">
                        <a href="https://hayrettinatmaca.org.tr/" target="_blank" rel="noopener noreferrer">
                            <button
                                className='navbar-button'
                                onClick={handleHayrettinatmacaClick}
                                style={{ background: activeButton === 'atmaca' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent' }}>
                                Hayrettin Atmaca Vakfı
                            </button>
                        </a>
                    </li>

                    <li className="main-nav_item">
                        <button
                            className='navbar-button'
                            onClick={handleHomeClick}
                            onContextMenu={(e) => handleContextMenu(e, '/home')}
                            style={{
                                background: activeButton === 'anasayfa' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent'
                            }}>
                            Anasayfa
                        </button>
                    </li>


                    <li className="main-nav_item" style={{ position: 'relative' }}>
                        <button className='navbar-button'
                            onClick={toggleOrganizasyon}
                            style={{
                                cursor: "pointer",
                                background: activeButton === 'organizasyon' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent',
                            }}>
                            Organizasyon
                        </button>

                        {isOrganizasyonOpen && (
                            <ul className="organizasyon-menu">
                                <li style={{ padding: '5px 1px' }} onClick={() => window.open('/pdf-files/Sunny Teknoloji Robo-Rally (2024-2025).pdf', '_blank')}>Uygulama Kılavuzu</li>
                                <li style={{ padding: '5px 1px' }} onClick={() => window.open('/pdf-files/Robo-Rally-Yarışma-Aşamaları (2024-2025).pdf', '_blank')}>Yarışma Aşamaları</li>
                                <li style={{ padding: '5px 1px' }} onClick={() => window.open('/pdf-files/Hayrettin Atmaca Vakfı_Yarışma_İnternet Sitesi KVKK_03.10.2024.pdf', '_blank')}>KVKK Aydınlatma Metni</li>
                                <li style={{ padding: '5px 1px' }}
                                    onClick={handleAnnouncementClick}
                                    onContextMenu={(e) => handleContextMenu(e, '/home/announcement')}
                                >
                                    Duyurular
                                </li>
                                <li style={{ padding: '5px 1px' }}
                                    onClick={handleTechnicAdvisorListClick}
                                    onContextMenu={(e) => handleContextMenu(e, '/home/technic-advisor-list')}
                                >
                                    Teknik Danışman Listesi
                                </li>
                                <li style={{ padding: '5px 1px' }}
                                    onClick={handleOrganizationExecutiveListClick}
                                    onContextMenu={(e) => handleContextMenu(e, '/home/organization-executive-list')}
                                >
                                    Organizasyon Yürütme Kurulu Listesi
                                </li>
                            </ul>
                        )}
                    </li>

                    <li className="main-nav_item" style={{ position: 'relative' }}>
                        <button className='navbar-button'
                            onClick={toggleArsiv}
                            style={{
                                cursor: "pointer",
                                background: activeButton === 'arsiv' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent',
                            }}>
                            Arşiv
                        </button>

                        {isArsivOpen && (
                            <ul className="arsiv-menu" >
                                <li onClick={handleArchiveHome2024Click}
                                    onContextMenu={(e) => handleContextMenu(e, '/home/archiveHome2024')}
                                >
                                    2024 | Roborally
                                </li>
                                <li onClick={handleArchiveHome2023Click}
                                    onContextMenu={(e) => handleContextMenu(e, '/home/archiveHome2023')}
                                >
                                    2023 | Labirent Yarışması
                                </li>
                            </ul>
                        )}
                    </li>

                    <li className="main-nav_item">
                        <button className='navbar-button'
                            onClick={handleContactClick}
                            onContextMenu={(e) => handleContextMenu(e, '/home/contact')}
                            style={{
                                background: activeButton === 'iletisim' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent',

                            }}>
                            İletişim
                        </button>
                    </li>
                    {userType !== null &&
                        <li className="main-nav_item" style={{ position: 'relative' }}>
                            <button className='navbar-button user-info-button'
                                onClick={toggleUser}
                            >

                                <span className='navbar-user-info'>{userFirstName.charAt(0).toUpperCase() + userLastName.charAt(0).toUpperCase()}</span>

                            </button>
                            {isUserOpen && userType === "ROLE_USER" && (
                                <ul className="user-menu" >
                                    <span>{userFirstName + " " + userLastName} </span>
                                    <li onClick={handleExamClick}  onContextMenu={(e) => handleContextMenu(e, '/home/user-home')}> Sınav </li>
                                    <li onClick={handleAskUsClick}  onContextMenu={(e) => handleContextMenu(e, '/home/ask-us-home')}>Bize Sor</li>
                                    <li onClick={handleTeamClick}  onContextMenu={(e) => handleContextMenu(e, '/home/my-team')}>Takımım</li>
                                    {/* <li onClick={handleAccommodationClick}  onContextMenu={(e) => handleContextMenu(e, '/home/accomodation')}>Konaklama Kayıt</li> */}
                                    <li onClick={handleLogOut}  onContextMenu={(e) => handleContextMenu(e, '/home')}>Çıkış</li>
                                </ul>
                            )}

                            {isUserOpen && userType === "ROLE_ADMIN" && (
                                <ul className="user-menu" >
                                    <span>{userFirstName + " " + userLastName} </span>
                                    <li onClick={handleManageAnnouncementClick} onContextMenu={(e) => handleContextMenu(e, '/home/manageAnnouncement')}>Duyuruları Yönet</li>
                                    <li onClick={handleManageInstitutionsClick} onContextMenu={(e) => handleContextMenu(e, '/home/manage-institutions')}>Kurumları Yönet</li>
                                    <li onClick={listAllUsersClick} onContextMenu={(e) => handleContextMenu(e, '/home/list-all-users')}>Kullanıcıları Listele</li>
                                    <li onClick={handleManageTechnicAdvisorClick} onContextMenu={(e) => handleContextMenu(e, '/home/manage-technic-advisor')}>Teknik Danışman Listesi Yönet</li>
                                    <li onClick={handleManageOrganizationExecutiveClick} onContextMenu={(e) => handleContextMenu(e, '/home/manage-organization-executive')}>Organizasyon Yürütme Kurulu Listesi Yönet</li>
                                    <li onClick={handleExamResultClick} onContextMenu={(e) => handleContextMenu(e, '/home/exam-result')}>Sınav Sonuçları</li>
                                    <li onClick={handleManageAskUsClick} onContextMenu={(e) => handleContextMenu(e, '/home/manageAskUs')}>Bize Sor & Cevap Yönetimi</li>
                                    <li onClick={handleManagePresentationClick} onContextMenu={(e) => handleContextMenu(e, '/home/managePresentation')}>Yarışmacı Sunumları</li>
                                    <li onClick={handleManageAccommodationClick} onContextMenu={(e) => handleContextMenu(e, '/home/manage-accomodation')}>Konaklama Yönet</li>
                                    <li onClick={handleAccommodationClick} onContextMenu={(e) => handleContextMenu(e, '/home/accomodation')}>Konaklama Kayıt</li>
                                    <li onClick={handleExamClick} onContextMenu={(e) => handleContextMenu(e, '/home/user-home')}> Sınav</li>
                                    <li onClick={handleAskUsClick} onContextMenu={(e) => handleContextMenu(e, '/home/ask-us-home')}>Bize Sor</li>
                                    <li onClick={handleTeamClick} onContextMenu={(e) => handleContextMenu(e, '/home/my-team')}>Takımım</li>
                                    <li onClick={handleLogOut} onContextMenu={(e) => handleContextMenu(e, '/home')}>Çıkış</li>
                                </ul>
                            )}
                        </li>
                    }

                    <li className="main-nav_item">
                        <a href="#" onClick={displayMenu}>
                            <FontAwesomeIcon icon={faBars} style={{ color: "white" }} />
                        </a>
                    </li>

                </div>
            </div>

        </div>
    );

}
