import '../../styles/ManageAnnouncement.css';
import '../../styles/Loading.css';
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import AnnouncementService from '../../services/AnnouncementService';
import HostAndPortInfo from "../../enums/EnHostandPortInfo";


const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function ManageAnnouncement() {

  const dropdownRef = useRef(null);
  const announcementService = new AnnouncementService();
  const [loading, setLoading] = useState(false); // Yüklenme durumu


  // modal icine default duyuru set etmek icin, null deger iceren nesne
  let empytAnnouncement = {
    "id": 0,
    "title": "",
    "content": "",
    "ImagePath": []
  }

  // Tarihe gore filtrelme yapmak icin
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('Tarih');

  const [announcements, setAnnouncements] = useState([]);
  const [announcementsDates, setAnnouncementsDates] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(empytAnnouncement);

  const [openModal, setOpenModal] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);


  //herhangi boş yere tıklandığında tarih dropdown kapansın
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //drapdown acma-kapama icin
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setDropdownOpen(false); // Tarih seçildikten sonra dropdown kapanır
  };


  useEffect(() => {

    setLoading(true)
    announcementService.getAllAnnouncement().then(result => {


      if (result.data.success) {

        // Tüm veriyi setAnnouncements ile set et
        setAnnouncements(result.data.data);

        // createDate değerlerini alıp setAnnouncementsDates'e at
        const createDates = result.data.data.map(announcement => announcement.createDate);
        setAnnouncementsDates(createDates); // Sadece createDate'leri ayarla      
        setLoading(false)
      }

    }).catch(error => {
      toast.error(error.response.data.data.message);
      setLoading(false)
    })



  }, [])

  // guncel tum duyuruları getir, tarih filtresini kaldır
  useEffect(() => {

    setSelectedDate("Tarih")

    announcementService.getAllAnnouncement().then(result => {

      if (result.data.success) {

        // Tüm veriyi setAnnouncements ile set et
        setAnnouncements(result.data.data);

        // createDate değerlerini alıp setAnnouncementsDates'e at
        const createDates = result.data.data.map(announcement => announcement.createDate);
        setAnnouncementsDates(createDates); // Sadece createDate'leri ayarla      

      }

    }).catch(error => {
      toast.error(error.response.data.data.message);

    })



  }, [openModal])

  // tarih filtresine gore getir.
  useEffect(() => {

    setCurrentPage(1); // ilk sayfaya gotur
    setLoading(true)
    //eger tarih seciliyse tarihe gore getir
    if (selectedDate !== "Tarih" && selectedDate !== "Tümü") {

      announcementService.getAllAnnouncementByDate(selectedDate).then(result => {

        if (result.data.success) {

          setAnnouncements(result.data.data)
          setLoading(false)

        }

      }).catch(error => {
        toast.error(error.response.data.data.message);
        setLoading(false)

      })
    } else if (selectedDate === "Tümü") {

      //tarih, tümü secili ise tumunu getir
      announcementService.getAllAnnouncement().then(result => {

        if (result.data.success) {

          // Tüm veriyi setAnnouncements ile set et
          setAnnouncements(result.data.data);

          // createDate değerlerini alıp setAnnouncementsDates'e at
          const createDates = result.data.data.map(announcement => announcement.createDate);
          setAnnouncementsDates(createDates); // Sadece createDate'leri ayarla
          setLoading(false)

        }

      }).catch(error => {
        toast.error(error.response.data.data.message);
        setLoading(false)

      })
    }

  }, [selectedDate])

  //card icin , 2024-09-11T08:21:55.350511  formatındaki tarihi 11 Eyl seklinde formatlar
  const formatDate = (dateString) => {
    const monthNames = { 1: "Oca", 2: "Şub", 3: "Mar", 4: "Nis", 5: "May", 6: "Haz", 7: "Tem", 8: "Ağu", 9: "Eyl", 10: "Eki", 11: "Kas", 12: "Ara" };

    const [year, month, dayWithTime] = dateString.split("-");
    const [day] = dayWithTime.split("T"); // Saat bilgisini ayırmak için

    // Ayı sayısal değere çevir ve monthNames içerisinden ayın adını al
    const monthName = monthNames[parseInt(month, 10)];


    return { month: monthName, day };
  };

  //dropdown icin, 2024-09-11T08:21:55.350511  formatındaki tarihi 2024-09-11 seklinde formatlar
  const formatDateDropdown = (dateString) => {

    const [year, month, dayWithTime] = dateString.split("-");
    const [day] = dayWithTime.split("T"); // Saat bilgisini ayırmak için

    const formatedDay = year + "-" + month + "-" + day

    return { formatedDay };
  };


  // sayfalama için
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = announcements.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(announcements.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  // duyuruları görüntüleyen modal için
  const handleCardClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setOpenModal(true);
  };

  // duyuru modalı kapat
  const handleCloseModal = () => {
    setSelectedAnnouncement(empytAnnouncement);
    setSelectedImageFile(null)
    setOpenModal(false);
  };

  const handleImageChange = (e) => {

    const file = e.target.files[0];
    if (file) {
      setSelectedImageFile(file); // Dosya objesini sakla     
    }
  };

  // duyuru kaydetme modalını acacak buton, boş duyuru nesnesini goster, kullanıcı icine veri set edecek.
  const addAnnouncementClick = () => {
    setSelectedAnnouncement(empytAnnouncement);
    setOpenModal(true);
  };

  // duyuru kaydetme modalının icindeki verileri kaydet, 
  //eğer o madalda mevcut bir kaydı görüntüleyip değişiklik yaptıysa kaydın id değeri vardır. O vakit guncelle
  const saveUpdateAnnouncement = () => {

    // Submit if all fields are valid
    if (selectedAnnouncement.title === "" || selectedAnnouncement.content === "") {

      toast.warning("Duyuru başlığı ve içeriğini boş bırakamazsınız !");
      return;
    }


    let formData = new FormData();

    formData.append('id', selectedAnnouncement.id);
    formData.append('title', selectedAnnouncement.title);
    formData.append("content", selectedAnnouncement.content);
    formData.append("file", selectedImageFile); // resim değiştikçe file dosyası dolu oluyor, resim seçmezse veya değiştirmezse default olarak null, arayüzde default resim gösterilir.


    if (selectedAnnouncement.id === 0) { // secilen duyurunun bir id si yok , o zaman kaydet
      announcementService.addAnnouncement(formData).then(result => {

        if (result.data.success === true) {

          toast.success("Duyuru başarıyla kaydedildi.");

          //sonra modalı kapat
          handleCloseModal();
        }
      }).catch(error => {

        toast.error(error.response.data.data.message);

        console.error(error);
      })
    } else { //secilen duyurunun bir id değeri var , guncelle

      announcementService.updateAnnouncement(selectedAnnouncement.id, formData).then(result => {

        if (result.data.success === true) {

          toast.success("Duyuru başarıyla güncellendi.");

          //sonra modalı kapat
          handleCloseModal();


        }
      }).catch(error => {

        toast.error(error.response.data.data.message);

        console.error(error);
      })
    }

    setSelectedImageFile(null) //son olarak secilen gorseli kaldır
  }

  const deleteAnnouncement = () => {

    announcementService.deleteAnnouncement(selectedAnnouncement.id).then(result => {

      if (result.status === 200) {

        toast.success("Duyuru başarıyla silindi.");

        handleCloseModal();


      }
    }).catch(error => {

      toast.error(error.response.data.data.message);

      console.error(error);
    })
  }


  return (
    <div className='admin-announcement-container'>

      <div className="admin-first" >
        <p className="admin-first-text">Duyuruları Yönet</p>
      </div>



      <div className="admin-my-container-announcement">

        <button className="add-announcement-btn" onClick={addAnnouncementClick}>Duyuru Ekle
          <FontAwesomeIcon icon={faPlus} className="admin-dropdown-icon" />
        </button>

        <div className="admin-dropdown" onClick={toggleDropdown}>
          <p className="admin-dropdown-text">
            {selectedDate}
            <FontAwesomeIcon icon={faChevronDown} className="admin-dropdown-icon" />
          </p>

          {isDropdownOpen && (
            <div ref={dropdownRef} className="admin-dropdown-content">
              {/* Tümünü seçeneğini ilk sıraya ekliyoruz */}
              <p key="all" onClick={() => handleDateSelect("Tümü")}>Tümü</p>

              {/* Aynı tarihleri önlemek için benzersiz bir Set kullanıyoruz */}
              {[...new Set(announcementsDates.map(date => formatDateDropdown(date).formatedDay))].map((uniqueDate) => (
                <p key={uniqueDate} onClick={() => handleDateSelect(uniqueDate)}>{uniqueDate}</p>
              ))}
            </div>
          )}
        </div>


        {loading === true &&
          <div className="loading-container">
            <div className="loading-icon"></div> {/* Loading ikonu */}
            <p>Veriler yükleniyor...</p>
          </div>
        }

        {loading === false &&
          <><div className="admin-announcementPart">
            {currentItems.slice(0, 3).map((announcement) => {
              const { month, day } = formatDate(announcement.createDate);
              return (
                <div className="card" key={announcement.id} onClick={() => handleCardClick(announcement)}>

                  {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                    announcement.ImagePath.map((imagePath, index) => (
                      <img
                        key={index}
                        src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                        className="card-img-top"
                        alt="..."
                      />
                    ))
                  ) : (
                    // Eğer ImagePath boş ise, varsayılan görseli göster
                    <img
                      src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                      className="card-img-top"
                      alt="Default"
                    />
                  )}


                  <div className="card-body">
                    <div className="card-content">
                      <div className="card-date">
                        <p className="card-date-month">{month.toLocaleUpperCase()}</p>
                        <p className="card-date-day">{day}</p>
                      </div>
                      <div className="card-text-content">
                        <h5 className="card-title">{announcement.title}</h5>
                        <p className="card-text">{announcement.content}</p>
                      </div>
                    </div>

                  </div>

                </div>
              );
            })}
          </div><div className="admin-announcementPart">

              {currentItems.slice(3, 6).map((announcement) => {
                const { month, day } = formatDate(announcement.createDate);

                return (
                  <div className="card" key={announcement.id} onClick={() => handleCardClick(announcement)}>

                    {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                      announcement.ImagePath.map((imagePath, index) => (
                        <img
                          key={index}
                          src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                          className="card-img-top"
                          alt="..."
                        />
                      ))
                    ) : (
                      // Eğer ImagePath boş ise, varsayılan görseli göster
                      <img
                        src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                        className="card-img-top"
                        alt="Default"
                      />
                    )}

                    <div className="card-body">
                      <div className="card-content">
                        <div className="card-date">
                          <p className="card-date-month">{month.toLocaleUpperCase()}</p>
                          <p className="card-date-day">{day}</p>
                        </div>
                        <div className="card-text-content">
                          <h5 className="card-title">{announcement.title}</h5>
                          <p className="card-text">{announcement.content}</p>
                        </div>
                      </div>


                    </div>
                  </div>
                );
              })}
            </div><div className="admin-announcementPart">

              {currentItems.slice(6, 9).map((announcement) => {
                const { month, day } = formatDate(announcement.createDate);

                return (
                  <div className="card" key={announcement.id} onClick={() => handleCardClick(announcement)}>

                    {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                      announcement.ImagePath.map((imagePath, index) => (
                        <img
                          key={index}
                          src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                          className="card-img-top"
                          alt="..."
                        />
                      ))
                    ) : (
                      // Eğer ImagePath boş ise, varsayılan görseli göster
                      <img
                        src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                        className="card-img-top"
                        alt="Default"
                      />
                    )}

                    <div className="card-body">
                      <div className="card-content">
                        <div className="card-date">
                          <p className="card-date-month">{month.toLocaleUpperCase()}</p>
                          <p className="card-date-day">{day}</p>
                        </div>
                        <div className="card-text-content">
                          <h5 className="card-title">{announcement.title}</h5>
                          <p className="card-text">{announcement.content}</p>
                        </div>

                      </div>

                    </div>
                  </div>

                );
              })}
            </div>

            {/* <div className='admin-pagination'>
              {[...Array(totalPages).keys()].map((_, index) => (
                <div key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                  <button className='admin-paginationButton' />
                </div>
              ))}
            </div> */}

            <div className='admin-pagination'>
              {currentPage > 1 && (
                <button onClick={handlePrevPage} className="admin-paginationButton">
                  <i className="fas fa-chevron-left"></i></button>
              )}

              {/* Sayfa numaralarını göster */}
              {[...Array(totalPages).keys()].map((_, index) => {
                const pageNumber = index + 1;

                // Sadece currentPage'e göre 3 sayfa numarası göster
                if (pageNumber === currentPage || pageNumber === currentPage - 1 || pageNumber === currentPage + 1) {
                  return (
                    <div key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                      <button className={`admin-paginationButton ${pageNumber === currentPage ? 'active' : ''}`}>
                        {pageNumber}
                      </button>
                    </div>
                  );
                }

                return null;
              })}

              {currentPage < totalPages && (
                <button onClick={handleNextPage} className="admin-paginationButton">
                  <i className="fas fa-chevron-right"></i>
                </button>
              )}
            </div>
          </>

        }


      </div>



      {
        openModal === true && (
          <div className="admin-modal">
            <div className="admin-modal-content">
              <button className="admin-close-button" onClick={handleCloseModal}>X </button>

              {selectedImageFile ? (
                // Kullanıcının seçtiği dosyayı base64 formatında göstermek için
                <img
                  src={URL.createObjectURL(selectedImageFile)} // Dosyayı geçici URL ile gösteriyoruz
                  className="admin-modal-top-image"
                  alt="Selected file"
                />
              ) : (
                // Eğer dosya seçilmediyse, mevcut dosya yollarını kontrol et
                (selectedAnnouncement.ImagePath && selectedAnnouncement.ImagePath.length > 0) ? (
                  selectedAnnouncement.ImagePath.map((imagePath, index) => (
                    <img
                      key={index}
                      src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                      className="admin-modal-top-image"
                      alt="..."
                    />
                  ))
                ) : (
                  // Eğer ImagePath boş ise, varsayılan görseli göster
                  <img
                    src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                    className="admin-modal-top-image"
                    alt="Default"
                  />
                )
              )}

              {/* Özelleştirilmiş dosya seçme butonu */}
              <label className="custom-file-upload">
                Fotoğraf Yükle
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }} // Dosya seçme inputunu gizle
                />
              </label>

              <div className="admin-modal-body">
                <textarea
                  className="admin-modal-title"
                  placeholder="Duyuru Başlığı"
                  rows={2}
                  value={selectedAnnouncement.title || ''}
                  onChange={(e) => setSelectedAnnouncement({
                    ...selectedAnnouncement, // Mevcut nesneyi kopyalar
                    title: e.target.value     // Sadece 'title' alanını günceller
                  })}
                />

                <textarea
                  className="admin-modal-text"
                  placeholder="Duyuru İçeriği"
                  rows={15}
                  value={selectedAnnouncement.content || ''}
                  onChange={(e) => setSelectedAnnouncement({
                    ...selectedAnnouncement, // Mevcut nesneyi kopyalar
                    content: e.target.value     // Sadece 'content' alanını günceller
                  })}
                />
              </div>

              <div className="card-buttons">
                <button type="button" className="admin-announcement-delete" onClick={deleteAnnouncement}>
                  Sil
                </button>
                <button type="button" className="admin-announcement-save" onClick={saveUpdateAnnouncement}>
                  Kaydet
                </button>

              </div>



            </div>
          </div>
        )
      }

    </div >
  )
}
