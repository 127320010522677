import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class PresentationFileService {

    addPresentationFile(data) {
        // kullanıclacak yerde bu şekilde data hazırlanmalı, sunumu sunucuya yükler, ardından yüklü mü diye kontrol sağlar 
        // let formData = new FormData();
        // formData.append('userId', userId);
        // formData.append("file", selectedFile);

        return axios({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/presentations`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
            data: data
        })
    }


    getAllPresentationFiles() {
        //directory seçilmemişse tamamını getirir, seçilmişse sadece ilgili directory getirir
        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/presentations`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }


    downloadPresentationFile(userId,fileName) {
        return axios({
            method: 'get',
            responseType: 'blob' ,// Blob olarak veri alıyoruz
            maxBodyLength: Infinity,
            url: `${baseURL}/api/presentations/download/${userId}?filename=${fileName}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }


    deletPresentationFile(userId,fileName) {
        return axios({
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/presentations/delete/${userId}?filename=${fileName}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    getPresentationByUserId(userId) {
        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/presentations/with-userId/${userId}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }
}