import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class OrganizationExecutiveService {

  getAllOrganizationExecutives() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/organization-executives`,
    })
  }

  saveOrganizationExecutive(saveDatas) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/organization-executives`,
      data: saveDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  updateOrganizationExecutive(id, updateDatas) { 

    return axios({
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/organization-executives/${id}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') },
      data: updateDatas
    })
  }

  deleteOrganizationExecutive(id) { 

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/organization-executives/${id}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') }
    })
  }

}
