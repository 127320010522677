import React, { useState, useEffect } from 'react';
import '../../styles/ArchiveDetail2024.css';


export default function ArchiveDetail2024({ clickedButton }) {

  const [activeButton, setActiveButton] = useState(clickedButton || 'denemeButton');
  const [visibleAllResults, setVisibleAllResults] = useState(false);

  const getButtonClass = (buttonName) => {
    return buttonName === activeButton ? 'menu-btn active' : 'menu-btn';
  };
  

  const imageContainer1Paths = [
    "goruntu-1.jpg",
    "goruntu-2.jpg",
    "goruntu-3.jpg",
    "goruntu-4.jpg",
    "goruntu-5.jpg",
    "goruntu-6.jpg",

  ];


  const imageContainer2Paths = [
    "goruntu-7.jpg",
    "goruntu-8.jpg",
    "goruntu-9.jpg",
    "goruntu-10.jpg",
    "goruntu-11.jpg",
    "goruntu-12.jpg"
  ];


  const [zoomedImage, setZoomedImage] = useState(null); // Zoom yapılacak görselin state'i
  const [isScrollingPaused, setIsScrollingPaused] = useState(false); // Kaydırma durumu

  const handleImageClick = (image) => {
    if (zoomedImage === image) {
      setZoomedImage(null); // Eğer aynı görsele tekrar tıklanırsa zoom'u kapat
      setIsScrollingPaused(false); // Kaydırmayı aç
    } else {
      setZoomedImage(image); // Görsel tıklandığında state güncellenir
      setIsScrollingPaused(true); // Kaydırmayı durdur
    }
  };

  return (
    <div className="archive">

      <div className="roboRally" style={{ marginTop: "2%" }}>
        <p className="roboRally-text">2024 RoboRally</p>
      </div>


      <div className="menu-btn-div" >
        <button type="button" className={getButtonClass('denemeButton')} onClick={() => { setActiveButton('denemeButton'); setVisibleAllResults(false) }}>
          Deneme Parkuru
        </button>

        <button type="button" className={getButtonClass('finalButton')} onClick={() => { setActiveButton('finalButton'); setVisibleAllResults(false) }}>
          Final Parkuru
        </button>

        <button type="button" className={getButtonClass('goruntuButton')} onClick={() => { setActiveButton('goruntuButton'); setVisibleAllResults(false) }} >
          Yarışma Görüntüleri
        </button>

        <button type="button" className={getButtonClass('dereceButton')} onClick={() => { setActiveButton('dereceButton'); setVisibleAllResults(false) }}>
          Dereceler
        </button>

      </div>


      {
        activeButton === "denemeButton" &&

        <><div className="my-container-archive">

          <div style={{ flex: "0.6" }}>


            <img className="parkur-img" alt="parkur" src={`${process.env.PUBLIC_URL}/images/archive/2024/parkur.png`} />


          </div>

          <div style={{ flex: "0.4" }}>

            <div className='deneme-parkur'>
              <p className='deneme-parkur-title'>Finale Giden Yolda Başarıya Hazırlık</p>
              <p className='deneme-parkur-txt' >Robot yarışmasının final parkuruna hazırlık sürecinde, öğrencilerin başarılı olmaları için onlara çeşitli imkanlar sağladık. Final parkurundaki
                zor dönüşlerin benzerlerini önceden denemeleri ve stratejilerinin geliştirebilmeleri için parkurları göndererek okullarında pratik yapmalarına
                olanak tanıdık. Final günü, yarışma başlamadan önce de sahada deneme yapmaları için zaman ayırarak, son hazırlıklarını yapmalarını sağladık.
                Bu sayede öğrenciler, hem teknik becerilerini pekiştirme hem de yarışma atmosferine alışma fırsatı buldular.
              </p>
            </div>



            <img className='parkur-img-2' alt="parkur-img-2" src={`${process.env.PUBLIC_URL}/images/archive/2024/parkur-yatay.png`} />


          </div>


        </div>

          <div className='photo-container'>

            <img alt="parkur" src={`${process.env.PUBLIC_URL}/images/archive/2024/deneme-first.png`} style={{ width: '27.5vw', height: '45vh', objectFit: 'cover', position: "relative" }} />
            <img alt="parkur" src={`${process.env.PUBLIC_URL}/images/archive/2024/deneme-second.png`} style={{ width: '20.5vw', height: '45vh', objectFit: 'contain', position: "relative" }} />
            <img alt="parkur" src={`${process.env.PUBLIC_URL}/images/archive/2024/deneme-third.png`} style={{ width: '20.5vw', height: '45vh', objectFit: 'contain', position: "relative" }} />
            <img alt="parkur" src={`${process.env.PUBLIC_URL}/images/archive/2024/deneme-fourth.png`} style={{ width: '28.5vw', height: '45vh', objectFit: 'cover', position: "relative" }} />


          </div></>
      }


      {
        activeButton === "finalButton" &&

        <><div className="my-container-archive">

          <div className='final-parkur-desc'>
            <p>Finalde Zorlu Mücadele</p>

            <p>Yarışmanın final günü, öğrenciler için büyük bir heyecan anlamına geliyordu.
              Final parkuru, yarışmanın en önemli aşaması olarak tasarlandı ve öğrenciler bu parkuru sadece yarışma sabahı görebildiler.
              Parkur üzerinde önceden deneme yapmalarına izin verilmedi, böylece herkes aynı şartlarda yarışma fırsatına sahip oldu.
            </p>

            <p >
              Öğrenciler, parkurda "Sunny" logosunu içeren çeşitli engelleri geçerek hedefe ulaşmaya çalıştılar. Parkur boyunca karşılaştıkları zorlukları aşarak
              başarılı olmaya çalıştılar. Genel sıralamada en iyi süreyi elde eden dört okul, finalde karşı karşıya gelerek en yüksek başarıyı elde etmek için
              mücadele etti. Bu düzenleme, tüm yarışmacılara eşit fırsatlar sunarak yeteneklerini sergilemelerini sağladı.
            </p>
          </div>

          <div className="final-images" >
            <img alt="final-first" src={`${process.env.PUBLIC_URL}/images/archive/2024/final-first.png`} />
            <img alt="final-second" src={`${process.env.PUBLIC_URL}/images/archive/2024/final-second.png`} />
          </div>


          <div className="final-images">
            <img alt="final-third" src={`${process.env.PUBLIC_URL}/images/archive/2024/final-third.png`} />
            <img alt="final-fourth" src={`${process.env.PUBLIC_URL}/images/archive/2024/final-fourth.png`} />
          </div>

        </div></>
      }


      {
        activeButton === "goruntuButton" &&

        <><div className="my-container-archive">


          <div className="yarisma-images" >
            <a href="https://www.youtube.com/watch?v=Je6Lqsu_OW0" target="_blank" rel="noopener noreferrer">
              <img alt="youtube-first" src={`${process.env.PUBLIC_URL}/images/archive/2024/youtube-first.png`} />
            </a>

            <a href="https://www.youtube.com/watch?v=6gmkhisat7A" target="_blank" rel="noopener noreferrer">
              <img alt="youtube-second" src={`${process.env.PUBLIC_URL}/images/archive/2024/youtube-second.png`} />
            </a>
          </div>


        </div>

        <div className={`photo-container-goruntu ${isScrollingPaused ? 'paused' : ''}`}>

          <div className={`yarisma-photo-container1 ${isScrollingPaused ? 'paused' : ''}`}>

          {[...imageContainer1Paths,...imageContainer1Paths, ...imageContainer1Paths, ...imageContainer1Paths].map((image, index) => (
                <div className='img-wrapper' key={index}>
                <img
                  alt={`yarisma-goruntu-${index + 1}`}
                  src={`${process.env.PUBLIC_URL}/images/archive/2024/${image}`}
                  className={zoomedImage === image ? 'zoomed' : ''}
                  onClick={() => handleImageClick(image)}
                  onTouchStart={() => handleImageClick(image)} // Tablet ve mobil için dokunma
                  onTouchEnd={() => setIsScrollingPaused(false)} // Dokunma bitince kaydırmayı aç

                />
              </div>
              ))}

            </div>

            <div className={`yarisma-photo-container2 ${isScrollingPaused ? 'paused' : ''}`}>

            {[...imageContainer2Paths,...imageContainer2Paths, ...imageContainer2Paths, ...imageContainer2Paths].map((image, index) => (
                 <div className='img-wrapper' key={index}>
                 <img
                   alt={`yarisma-goruntu-${index + 7}`}
                   src={`${process.env.PUBLIC_URL}/images/archive/2024/${image}`}
                   className={zoomedImage === image ? 'zoomed' : ''}
                   onClick={() => handleImageClick(image)}
                   onTouchStart={() => handleImageClick(image)} // Tablet ve mobil için dokunma
                   onTouchEnd={() => setIsScrollingPaused(false)} // Dokunma bitince kaydırmayı aç

                 />
               </div>
              ))}
            </div>
          </div>


        </>
      }

      {
        activeButton === "dereceButton" && visibleAllResults === false &&

        <>
          <div className="my-container-archive">


            <img className="derecekazananlar2024" alt="derece 2024 kazananlar" src={`${process.env.PUBLIC_URL}/images/archive/2024/derece-2024-kazananlar.png`} />

            <button type="button" className="menu-btn" style={{ marginTop: "7%", filter: "blur(0)" }} onClick={() => setVisibleAllResults(true)}>
              Tüm Sonuçlar
            </button>


          </div>

        </>

      }


      {
        visibleAllResults === true &&

        <div className="my-container-archive">

          <img className="yarisanlar2024" alt="yarisanlar 2024" src={`${process.env.PUBLIC_URL}/images/archive/2024/derece-2024.png`} />

        </div>


      }

    </div>
  )
}
