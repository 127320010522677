import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class CityService {

  getAllCity() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/city`,
    })
  }

  getAllCityWithUserCount() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/city/city-with-user-count`,
    })
  }
}