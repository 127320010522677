import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class AccommodationService {  

  saveAccommodation(saveDatas) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/accommodations`,
      data: saveDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  getAllAccommodationRecords() {

    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/accommodations`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  getAccomodationByUserId(userId) {

    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/accommodations/with-userId/${userId}`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  deleteAccomodationByUserId(userId) {

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/accommodations/${userId}`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

}
