import React, { useState, useEffect } from 'react'
import '../../styles/DarkTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import TechnicalAdvisorService from '../../services/TechnicalAdvisorService';
import { toast } from "react-toastify";

export default function ManageTechnicalAdvisorsList() {

    const technicalAdvisorService = new TechnicalAdvisorService()

    const [refreshData, setRefreshData] = useState(false);

    const [technicalAdvisors, setTechnicalAdvisor] = useState([]);
    const [selectedAdvisorId, setSelectedAdvisorId] = useState(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateFormData, setUpdateFormData] = useState({
        name: '',
        title: '',
        dutyLocation: '',
        dutyTaskDescription: ''
    });

    const [showAddModal, setShowAddModal] = useState(false);
    const [addFormData, setAddFormData] = useState({
        name: '',
        unvan: '',
        dutyLocation: '',
        dutyTaskDescription: ''
    });

    useEffect(() => {

        technicalAdvisorService.getAllTechnicalAdvisors().then(result => {

            console.log(result)
            if (result.data.success) {
                setTechnicalAdvisor(result.data.data);
            }

        }).catch(error => {
            toast.error("Teknik danışmanları listelerken bir sorun oluştu!");
        })

    }, [refreshData]);

    const handleUpdate = (id) => {

        const selectedAdvisor = technicalAdvisors.find(advisor => advisor.id === id);
        setUpdateFormData({
            name: selectedAdvisor.name,
            title: selectedAdvisor.title,
            dutyLocation: selectedAdvisor.dutyLocation,
            dutyTaskDescription: selectedAdvisor.dutyTaskDescription
        });

        setSelectedAdvisorId(id);
        setShowUpdateModal(true);
    };

    const handleCloseUpdateModal = () => {

        setUpdateFormData({ name: '', title: '', dutyLocation: '', dutyTaskDescription: '' });
        setSelectedAdvisorId(null);
        setShowUpdateModal(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveUpdate = () => {
        console.log('Güncellenen veri:', updateFormData);

        technicalAdvisorService.updateTechnicalAdvisor(selectedAdvisorId, updateFormData).then(result => {

            if (result.data.success) {
                toast.success("Veriler güncellendi.")
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Veriler güncellenirken bir hata oluştu!");
        })


        setShowUpdateModal(false);
    };

    const handleDelete = (id) => {
        setSelectedAdvisorId(id);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        console.log(`Silme işlemi gerçekleştirildi, ID: ${selectedAdvisorId}`);

        technicalAdvisorService.deleteTechnicalAdvisor(selectedAdvisorId).then(result => {

            console.log(result)
            if (result.status === 200) {
                toast.success("Üye silindi.");
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Üye silinirken bir hata oluştu!");
        })

        setShowDeleteModal(false);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setAddFormData({ name: '', title: '', dutyLocation: '', dutyTaskDescription: '' });
    };

    const addHandleInputChange = (e) => {
        const { name, value } = e.target;
        setAddFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addHandleSave = () => {
        console.log('Eklenen veriler:', addFormData);

        technicalAdvisorService.saveTechnicalAdvisor(addFormData).then(result => {

            if (result.data.success) {
                toast.success("Veriler kaydedildi.")
                setRefreshData(prev => !prev);
                setAddFormData({ name: '', title: '', dutyLocation: '', dutyTaskDescription: '' });
            }

        }).catch(error => {
            toast.error("Veriler kaydedilirken bir hata oluştu!");
        })

        setShowAddModal(false);
    };

    return (

        <div className="dark-tb-main">

            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">TEKNİK DANIŞMAN LİSTESİ</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">UNVANI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREV YERİ</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">YARIŞMA GÖREV TANIMI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">İŞLEMLER
                                <div>
                                    <Button className="dark-tb-add-btn" variant="success" onClick={handleAdd}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </div>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {technicalAdvisors.map((item, index) => (
                            <tr key={item.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{item.name}</td>
                                <td className="dark-tb-body-text">{item.title}</td>
                                <td className="dark-tb-body-text">{item.dutyLocation}</td>
                                <td className="dark-tb-body-text">{item.dutyTaskDescription}</td>
                                <td>
                                    <button
                                        className="btn btn-sm btn-warning dark-tb-update-btn"
                                        onClick={() => handleUpdate(item.id)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button className="btn btn-sm btn-danger dark-tb-delete-btn"
                                        onClick={() => handleDelete(item.id)}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Güncelleme Modali */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Güncelleme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>ADI | SOYADI</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={updateFormData.name || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formTitle">
                            <Form.Label>UNVANI</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={updateFormData.title || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formLocation">
                            <Form.Label>GÖREV YERİ</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={updateFormData.dutyLocation || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formRole">
                            <Form.Label>YARIŞMA GÖREV TANIMI</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyTaskDescription"
                                value={updateFormData.dutyTaskDescription || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={handleSaveUpdate}>
                        Güncellemeyi Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Silme Onay Modali */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">Bu kaydı silmek istediğinizden emin misiniz?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Evet, Sil
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Data Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Üye Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={addFormData.name}
                                onChange={addHandleInputChange}
                                placeholder="Adı Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Unvanı</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={addFormData.title}
                                onChange={addHandleInputChange}
                                placeholder="Unvan Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Yeri</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={addFormData.dutyLocation}
                                onChange={addHandleInputChange}
                                placeholder="Görev Yeri Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Yarışma Görev Tanımı</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyTaskDescription"
                                value={addFormData.dutyTaskDescription}
                                onChange={addHandleInputChange}
                                placeholder="Yarışma Görev Tanımı Girin"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={addHandleSave}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

    )
}
