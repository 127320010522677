import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/FrequentlyAskedQuestions.css';
import '../../styles/Loading.css';
import { toast } from "react-toastify";
import QuestionService from '../../services/QuestionService';

import HostAndPortInfo from "../../enums/EnHostandPortInfo";
const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function FrequentlyAskedQuestions() {

    const questionService = new QuestionService()

    const [loading, setLoading] = useState(false); 
    const [questions, setQuestions] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null); 

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {

        setLoading(true)

        questionService.getFAQuestions().then(result => {

            if (result.data.success) {
                setQuestions(result.data.data);
            }
        }).catch(error => {

            toast.error(error.response.data.data.message);
        }).finally(() => {

            setLoading(false);
        });


    }, [refresh])

    const handleDetailClick = (question) => {
        setShowDetail(!showDetail)
        setSelectedQuestion(question);
    };

    const handleReturnDetailClick = () => {
        setShowDetail(!showDetail)
        setSelectedQuestion(null); 
    };

    return (
        <div className='frequently-asked-questions-container'>

            {loading === true &&
                <div className="loading-container">
                    <div className="loading-icon"></div> {/* Loading ikonu */}
                    <p>Veriler yükleniyor...</p>
                </div>
            }

            {showDetail === false && loading === false && (

                questions.map((question, index) => (
                    <div className='frequently-asked-questions-user-container' key={index}>

                        <div className='user-question-mid'>
                            <p>{question.title}</p>
                            <p>{question.description}</p>
                        </div>

                        <div className='user-question-bottom'>
                            {question.response === null ? (
                                <button onClick={() => handleDetailClick(question)}>
                                    Cevap Bekleniyor...
                                </button>
                            ) : (
                                <button onClick={() => handleDetailClick(question)}>
                                    Soruyu Aç
                                </button>
                            )}
                        </div>

                    </div>
                ))
            )}

            {showDetail === true && selectedQuestion && (
                <>
                    <div className='frequently-asked-questions-user-container'>
                        <div className='user-question-detail'>
                            <p>{selectedQuestion.title}</p>
                            <p>{selectedQuestion.description}</p>
                        </div>

                        <div className='user-question-detail-imgs'>
                            {selectedQuestion.questionImagePaths.map((imagePath, index) => (
                                <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="question image" />
                            ))}
                        </div>
                    </div>

                    {selectedQuestion.response && (
                        <div className='frequently-asked-questions-user-container'>
                            <div className='admin-question-response'>
                                <p>CEVAP: {selectedQuestion.response}</p>
                            </div>

                            <div className='user-question-detail-imgs'>
                                {selectedQuestion.responseImagePaths.map((imagePath, index) => (
                                    <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="response image" />
                                ))}
                            </div>
                        </div>
                    )}

                    <button id='return-faq-question-button' onClick={() => handleReturnDetailClick()}>
                        Sık Sorulan Sorulara Geri Dönün
                    </button>
                </>
            )}

        </div>
    )
}