import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/ArchiveHome2024.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

export default function ArchiveHome2024({ setClickedButton }) {

  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setClickedButton(buttonName)
    navigate('/home/archiveDetail2024');
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true); // Kontrollerin görünürlüğü için state

  const videoRef = React.createRef();
  let hideControlsTimeout = null;

  const handleVideoClick = () => {
    // Kontrolleri göster
    setShowControls(true);
    handlePlayPause();

    // Zamanlayıcı ile kontrolleri gizle
    if (hideControlsTimeout) clearTimeout(hideControlsTimeout);
    hideControlsTimeout = setTimeout(() => {
      setShowControls(false);
    }, 2000); // 2 saniye sonra kontrolleri gizle
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Video bittiğinde oynat butonunu göster
  const handleVideoEnded = () => {
    setIsPlaying(false);
    setShowControls(true); // Video bittiğinde kontrolleri göster
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('ended', handleVideoEnded);
    }
    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, []);

  return (
    <div className='archive-home-container'>


      <div className="roboRally2024" style={{ marginTop: "2%" }}>
        <p className="roboRally2024-text">2024 Roborally</p>
      </div>


      <div className="roborally2024-winner-div" >
        <div className="vector-container">
          <img
            src="../images/archive/archive-vector-1.png"
            alt="Overlay Image"
            className="vector1-image"
          />

          <p className="RoborallyBirincisi-text">
            2024 RoboRally Birincisi  </p>

          <p className="IgdirBilimSanat-text">
            Iğdır Bilim Sanat Merkezi
          </p>

          <img
            src="../images/archive/archive-vector.png"
            alt="Overlay Image"
            className="vector-image"
          />

          <p className="UzunYazi-text">
            SUNNY Elektronik'in öğrencilerin robotik konusunda kendilerini geliştirmeleri için hayata geçirdiği "Ağrı'nın Zirvesi'nde Teknoloji Yarışması" finali 2 bin 500 kişinin üzerinde geniş bir katılımla gerçekleşti. İkinci kez düzenlenen yarışmada finale kalan 20 ekip, kendi tasarımları olan robotlarını, belirlenen parkurda final noktasına en kısa sürede ulaştırmak için yarıştı. Heyecanlı anların yaşandığı yarışmayı
            <span style={{ color: "orange", fontFamily: "LufgaMedium" }}> 1 dakika 22 sn. ile Iğdır - Bilim Sanat Merkezi </span>kazandı. Kazanan ekip 100 bin TL birincilik ödülünün sahibi oldu. Yarışmada ikinci olan Iğdır - Aras Mesleki ve Teknik Anadolu Lisesi ekibi ikinci 75 bin TL, üçüncü olan Ağrı - 15 Temmuz Şehitler Anadolu Lisesi ekibi ise 50 bin TL ile ödüllendirildi.</p>

          <img
            src="../images/archive/archive-vector2.png"
            alt="Overlay Image"
            className="vector2-image"
          />

        </div>
      </div>

      <div className="menu-buton-div" >
        <button type="button" className='menu-buton' onClick={() => handleButtonClick("denemeButton")} >
          Deneme Parkuru
        </button>

        <button type="button" className='menu-buton' onClick={() => handleButtonClick("finalButton")} >
          Final Parkuru
        </button>

        <button type="button" className='menu-buton' onClick={() => handleButtonClick("goruntuButton")} >
          Yarışma Görüntüleri
        </button>

        <button type="button" className='menu-buton' onClick={() => handleButtonClick("dereceButton")}>
          Dereceler
        </button>

      </div>

      <div className="news-istanbul-div" >
        <p className='news-istanbul-title'>
          2024 KAZANANLARI <span>İSTANBUL</span>'DA AĞIRLADIK.
        </p>

        <div className='news-istanbul-description' >
          Geleceğin teknoloji lideriyle birlikte, unutulmaz bir deneyimi paylaştık.<br />
          Yarışmamızla onların kariyer yolculuklarına katkı sağlamaktan gurur duyuyoruz.
        </div>


        <div className='news-istanbul-video' onClick={handleVideoClick}>
          <video ref={videoRef} className='video-element' width="100%">

            <source src={`${process.env.PUBLIC_URL}/videos/archive/Azty2024.mp4`} type="video/mp4" />
            Tarayıcınız bu videoyu oynatmayı desteklemiyor.

          </video>
          {showControls && (

            <button onClick={handlePlayPause} className='play-button'>
              {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
            </button>

          )}

        </div>  
      </div>
    </div>
  )
}
