import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../styles/ArchiveHome2023.css';

export default function ArchiveHome2023({setClickedButton}) {

    const navigate = useNavigate();

    const handleButtonClick = (buttonName) => {
        
      setClickedButton(buttonName)
      navigate('/home/archiveDetail2023');
    };


    return (
        <div className='archive-home-2023-container'>

            <div className="roboRally2023" style={{ marginTop: "2%" }}>
                <p className="roboRally2023-text">2023 Labirent Yarışması</p>
            </div>


            <div className='roborally-2023-description-div'>


                <p className="roborally-2023-first-text">
                    2023 Ağrı'nın Zirvesinde Teknoloji Yarışması
                    <img
                        src="../images/archive/archive-vector-1.png"
                        alt="Overlay Image"
                        className="vector1-image-2023"
                    />
                    <br /> Birincisi
                    <span className="roborally-2023-school-text"> Ağrı Taşlıçay Anadolu Lisesi
                        <img
                            src="../images/archive/archive-vector.png"
                            alt="Overlay Image"
                            className="vector-image-2023" />
                    </span>
                </p>




                <p className="roborally-2023-description-text">
                    Yarışmamıza katılan, gece gündüz emek verip çabalayan tüm öğrencilerimize, öğrencilerimize ışık tutan öğretmenlerimize, öğretmenlerimize her türlü
                    kolaylığı sağlamak için tüm imkanlarını seferber eden Ağrı İl Milli Eğitim Müdürlüğü' ne canı gönülden teşekkür ederiz. Yarışmaya katılan tüm gençlerimizin
                    gözündeki ışık, bizim bu ülkeye olan inancımızı bir kez daha perçinledi. Gençlerimizin teknolojiye olan ilgi ve merakını arttırmak için, Türkiye'nin
                    yerli ve milli markası Sunny her zaman yanınızda olmaya devam edecek.

                    <img
                        src="../images/archive/archive-vector2.png"
                        alt="Overlay Image"
                        className="vector2-image-2023"
                    />
                </p>

            </div>

            <div className="menu-buton-div-2023" >

                <button type="button" className='menu-buton-2023' onClick={() => handleButtonClick("goruntuButton")} >
                    Yarışma Görüntüleri
                </button>

                <button type="button" className='menu-buton-2023' onClick={() => handleButtonClick("dereceButton")}>
                    Dereceler
                </button>

            </div>
        </div>
    )

}
