import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import '../styles/Team.css';
import TeamService from '../services/TeamService';
import { useLocation } from 'react-router-dom'; // useLocation ile state'i yakalayacağız



export default function Team() {

    const teamService = new TeamService();

    const [originalFirstStudent, setOriginalFirstStudent] = useState(null); // Orijinal ilk öğrenci
    const [originalSecondStudent, setOriginalSecondStudent] = useState(null); // Orijinal ikinci öğrenci

    const [firstStudent, setFirstStudent] = useState({ id: "", firstName: "", lastName: "", identityNo: "", userInfo: { firstName: "", lastName: "", institutionCity: "", institutionName: "" } });
    const [secondStudent, setSecondStudent] = useState({ id: "", firstName: "", lastName: "", identityNo: "", userInfo: { firstName: "", lastName: "", institutionCity: "", institutionName: "" } });

    const [studentError, setStudentError] = useState({});

    const [activeButton, setActiveButton] = useState('firstStudent');
    const [isUpdateStudent, setIsUpdateStudent] = useState(false);
    const [refresh, setRefresh] = useState(false); // Durum değişkeni

    useEffect(() => {

        teamService.getTeamByUserId().then(result => {

            //console.log(result.data.data)
            if (result.data.success && result.data.data.length != 0) {
                setFirstStudent(result.data.data[0]);
                setSecondStudent(result.data.data[1])

                setOriginalFirstStudent(result.data.data[0]); // Orijinal veriyi sakla
                setOriginalSecondStudent(result.data.data[1]); // Orijinal veriyi sakla
            }

        }).catch(error => {
            const fullMessage = error.response.data.data.message;
            const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
            const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
            toast.error(message);
        })

    }, [refresh]);


    const handleActiveButtonClick = (student) => {
        setActiveButton(student);
    };

    const isValidTc = (tc) => {
        // TC numarası 11 haneli olmalı ve ilk karakter 0 olamaz
        if (!/^[1-9][0-9]{10}$/.test(tc)) return false;

        let sumOdd = 0;  // 1, 3, 5, 7, 9. basamakların toplamı
        let sumEven = 0; // 2, 4, 6, 8. basamakların toplamı
        let sumFirst10 = 0;  // İlk 10 basamağın toplamı

        // Basamakları dolaşarak toplamları hesapla
        for (let i = 0; i < 9; i++) {
            const digit = parseInt(tc[i]);
            sumFirst10 += digit;
            if (i % 2 === 0) {
                sumOdd += digit;  // Tek basamaklar (1, 3, 5...)
            } else {
                sumEven += digit; // Çift basamaklar (2, 4, 6...)
            }
        }

        const digit10 = ((sumOdd * 7) - sumEven) % 10;  // 10. hane
        sumFirst10 += parseInt(tc[9]);  // 10. basamağı da ilk 10 toplamına ekle
        const digit11 = sumFirst10 % 10;  // 11. hane

        // 10. ve 11. basamaklar kontrol ediliyor
        return digit10 === parseInt(tc[9]) && digit11 === parseInt(tc[10]);
    };

    const handleSubmit = () => {

        setStudentError({})

        switch (activeButton) {

            case 'firstStudent':

                // İlk öğrenciyi güncelle

                if (!firstStudent.firstName || !firstStudent.lastName || !firstStudent.identityNo) {

                    setStudentError({ student: 'ÖĞRENCİ I adı, soyadı ve TC numarası gerekli.' });
                    return;
                }


                if (!isValidTc(firstStudent.identityNo)) {
                    setStudentError({ student: 'ÖĞRENCİ I için geçerli bir TC numarası giriniz.' });
                    return;
                }

                // Orijinal veriyle kıyasla
                if (JSON.stringify(firstStudent) === JSON.stringify(originalFirstStudent)) {
                    toast.warning("ÖĞRENCİ I verilerinde değişiklik yok.");
                    return;
                }


                // Create data objects student
                const firstStudentData = {
                    id: firstStudent.id,
                    firstName: firstStudent.firstName,
                    lastName: firstStudent.lastName,
                    identityNo: firstStudent.identityNo,

                };

                teamService.updateTeam(firstStudent.id, firstStudentData)
                    .then(result => {
                        if (result.data.success) {
                            toast.success("ÖĞRENCİ I güncellendi!");
                            setRefresh(prev => !prev); // refresh durumunu değiştir
                        }
                    })
                    .catch(error => {
                        setStudentError({ student: "ÖĞRENCİ I güncellenemedi!" });
                        toast.error("Bir hata oluştu.");
                    });
                break;

            case 'secondStudent':
                // İkinci öğrenciyi güncelle 

                if (!secondStudent.firstName || !secondStudent.lastName || !secondStudent.identityNo) {

                    setStudentError({ student: 'ÖĞRENCİ II adı, soyadı ve TC numarası gerekli.' });
                    return;
                }

                if (!isValidTc(secondStudent.identityNo)) {
                    setStudentError({ student: 'ÖĞRENCİ II için geçerli bir TC numarası giriniz.' });
                    return;
                }

                // Orijinal veriyle kıyasla
                if (JSON.stringify(secondStudent) === JSON.stringify(originalSecondStudent)) {
                    toast.warning("ÖĞRENCİ II verilerinde değişiklik yok.");
                    return;
                }
                // Create data objects for both student
                const secondStudentData = {

                    id: secondStudent.id,
                    firstName: secondStudent.firstName,
                    lastName: secondStudent.lastName,
                    identityNo: secondStudent.identityNo,

                };

                teamService.updateTeam(secondStudent.id, secondStudentData)
                    .then(result => {
                        if (result.data.success) {
                            toast.success("ÖĞRENCİ II güncellendi!");
                            setRefresh(prev => !prev); // refresh durumunu değiştir

                        }
                    })
                    .catch(error => {
                        setStudentError({ student: "ÖĞRENCİ II güncellenemedi!" });
                        toast.error("Bir hata oluştu.");
                    });
                break;

            default:
                // Hiçbir öğrenci seçilmediyse bir uyarı ver
                toast.error("Bir öğrenci seçmelisiniz!");
                break;
        }
    };


    //her kelimenin ilk harfini büyük, geri kalanını küçük yapacak şekilde
    const capitalizeWords = (text) => {
        return text
            .split(' ')
            .map(word => word.charAt(0).toLocaleUpperCase('tr-TR') + word.slice(1).toLocaleLowerCase('tr-TR'))
            .join(' ');
    };

    return (
        <div className='my-team-bg'>

            <div className='my-team-container'>

                <div className='title-card'>
                    <span>Takımım</span>
                </div>


                {isUpdateStudent === false && (<div className='team-student-container'>
                    <span>{capitalizeWords(firstStudent.userInfo.firstName + " " + firstStudent.userInfo.lastName)}</span>
                    <span>{capitalizeWords(firstStudent.userInfo.institutionCity)}</span>
                    <span>{capitalizeWords(firstStudent.userInfo.institutionName)}</span>
                </div>)}



                <div className='team-student-container'>

                    <label>ÖĞRENCİ I</label>

                    <div className='team-student-card'>
                        <p className='team-student-name'>{firstStudent.firstName + " " + firstStudent.lastName}</p>
                        <p className='team-student-tc'>{firstStudent.identityNo}</p>
                    </div>

                </div>

                <div className='team-student-container'>

                    <label>ÖĞRENCİ II </label>

                    <div className='team-student-card'>
                        <p className='team-student-name'>{secondStudent.firstName + " " + secondStudent.lastName}</p>
                        <p className='team-student-tc'>{secondStudent.identityNo}</p>
                    </div>


                </div>


                {isUpdateStudent === false && (

                    <button type="button" className="team-update-btn" onClick={() => { setIsUpdateStudent(true) }} >
                        Öğrenci Değiştir
                    </button>
                )}

                {isUpdateStudent === true && (
                    <><span className="team-update-desc">Değişiklik yapmak istediğiniz öğrenciyi seçiniz.</span><div className='team-students-btns'>
                        <button
                            type="button"
                            className={activeButton === 'firstStudent' ? 'team-student-active' : 'team-student-pasive'}
                            onClick={() => handleActiveButtonClick('firstStudent')}

                        >
                            ÖĞRENCİ I
                        </button>

                        <button
                            type="button"
                            className={activeButton === 'secondStudent' ? 'team-student-active' : 'team-student-pasive'}
                            onClick={() => handleActiveButtonClick('secondStudent')}

                        >
                            ÖĞRENCİ II
                        </button>
                    </div>


                        <div className='team-update-form'>

                            {activeButton === 'firstStudent' && (
                                <form className='team-form'>
                                    <div className="mb-3">
                                        <label htmlFor="studentName" className="team-update-label">Öğrenci Ad</label>
                                        <input
                                            type="text"
                                            id="studentName"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => setFirstStudent({ ...firstStudent, firstName: e.target.value })}
                                            value={firstStudent.firstName} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="studentName" className="team-update-label">Öğrenci Soyad</label>
                                        <input
                                            type="text"
                                            id="studentLastname"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => setFirstStudent({ ...firstStudent, lastName: e.target.value })}
                                            value={firstStudent.lastName} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="studentTc" className="team-update-label">Öğrenci TC</label>
                                        <input
                                            type="number"
                                            id="studentTc"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value.length <= 11) {
                                                    setFirstStudent({ ...firstStudent, identityNo: value });
                                                }
                                            }}
                                            value={firstStudent.identityNo} />
                                    </div>

                                    {studentError.student && <small className="text-danger">{studentError.student}</small>}


                                </form>
                            )}

                            {activeButton === 'secondStudent' && (
                                <form className='team-form'>
                                    <div className="mb-3">
                                        <label htmlFor="studentName" className="team-update-label">Öğrenci Ad</label>
                                        <input
                                            type="text"
                                            id="studentName"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => setSecondStudent({ ...secondStudent, firstName: e.target.value })}
                                            value={secondStudent.firstName} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="studentName" className="team-update-label">Öğrenci Soyad</label>
                                        <input
                                            type="text"
                                            id="studentLastname"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => setSecondStudent({ ...secondStudent, lastName: e.target.value })}
                                            value={secondStudent.lastName} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="studentTc" className="team-update-label">Öğrenci TC</label>
                                        <input
                                            type="number"
                                            id="studentTc"
                                            className="form-control team-update-input"
                                            required
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value.length <= 11) {
                                                    setSecondStudent({ ...secondStudent, identityNo: value });
                                                }
                                            }}
                                            value={secondStudent.identityNo} />
                                    </div>

                                    {studentError.student && <small className="text-danger">{studentError.student}</small>}


                                </form>
                            )}



                            <button type="button" className="team-update-btn" onClick={handleSubmit}>
                                Kaydet
                            </button>
                        </div></>

                )}

            </div>
        </div>
    )
}
