import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/StartExam.css';
import ExamRecordService from '../services/ExamRecordService';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import InfoModal from '../layouts/modals/InfoModal';

export default function StartExam() {

    const navigate = useNavigate();
    const examRecordService = new ExamRecordService()

    const userId = sessionStorage.getItem("userId");

    const [accepted, setAccepted] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleAccept = () => {
        setAccepted(!accepted);
    };

    const startExam = async () => {

        if (accepted) {

            try {
                const result = await examRecordService.getExamRecordsByUserId(userId)

                if (result.data.data.examRecords.length === 0) {

                    navigate("/exam");

                } else {
                    setModalTitle("⚠️UYARI !")
                    setModalBody("Sınava iki kez katılım sağlayamazsınız!")
                    setShowModal(true);
                }

            } catch (error) {
                setModalTitle("⚠️UYARI !")
                setModalBody("Sınava iki kez katılım sağlayamazsınız!")
                setShowModal(true);
                return;
            }

        } else {

            setModalTitle("⚠️UYARI !")
            setModalBody("Lütfen önce sınav kurallarını onaylayın!")
            setShowModal(true);
        }
    };

    useEffect(() => {

        // Tarih kontrol fonksiyonu
        const checkDate = () => {
            const now = new Date();

            // Başlangıç ve bitiş tarihleri
            const startDate = new Date('2024-11-20T10:00:00'); // Başlangıç tarihi
            const endDate = new Date('2024-11-20T12:00:00'); // Bitiş tarihi

            // Eğer şu anki tarih bu aralıktaysa butonu aktif yap
            if (now >= startDate && now <= endDate) {
                
            } else {
                navigate("/home")
            }
        };

        checkDate();

        // Saniyede bir kontrol edecek interval ayarla
        const intervalId = setInterval(checkDate, 1000);
        // Bileşen unmount olduğunda interval'ı temizle
        return () => clearInterval(intervalId);

    }, []);

    return (

        <div className="start-exam-executive-main">

            <div className="container mt-5">

                <div className="rules p-4 mb-4">

                    <h1 className="start-exam-header mb-4">
                        <FontAwesomeIcon className='start-exam-header-icon' icon={faCircleExclamation} />
                        Sınav Kuralları ve Katılım Şartları
                    </h1>
                    <p>Yarışmamıza başvurmadan önce aşağıdaki kuralları dikkatlice okuyun ve onaylayın:</p>

                    <div>
                        <ol>
                            <li>
                                <strong className='start-exam-list-header'>Sunum Yükleme:</strong>
                                <ul>
                                    <li>
                                        Sınava başlamadan önce hazırladığınız sunumu sisteme yüklemeniz gerekecek.
                                        Yüklenen sunumlar daha sonra değiştirilemeyecektir. Lütfen tüm kontrollerinizi yaptıktan sonra sunumunuzu yükleyin.
                                    </li>
                                    <li>
                                        Sunumunuzu sisteme yüklemeden önce ZIP formatına dönüştürmeniz gerekmektedir!
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong className='start-exam-list-header'>Sınav Süresi:</strong>
                                <ul>
                                    <li>Sınav, başladıktan sonra <span className='warning-text'>1.5 saat (90dk)</span> boyunca açık kalacaktır. Süre dolduğunda sınav otomatik olarak sona erecektir ya da tüm soruları cevaplayarak sınavınızı tamamlayabilirsiniz.</li>
                                    <li>Soru başına ayrılan süre yoktur, ancak toplam süre içinde tüm soruları tamamlamanız gerekmektedir.</li>
                                </ul>
                            </li>
                            <li>
                                <strong className='start-exam-list-header'>Geri Dönüş Yok:</strong>
                                <ul>
                                    <li>Her soruyu yanıtladıktan sonra, bir önceki soruya geri dönemezsiniz.
                                        Yanıtlarınızı dikkatlice yazdıktan sonra bir sonraki soruya geçiniz.</li>
                                </ul>
                            </li>
                            <li>
                                <strong className='start-exam-list-header'>Onay ve Başlangıç:</strong>
                                <ul>
                                    <li>Kuralları kabul edip onayladıktan sonra sizi sunum yükleme sayfası karşılayacaktır.
                                        Sunumunuzun bulunduğu ZIP uzantılı dosyayı seçtikten sonra ilgili butona basarak sınava başlayabilirsiniz.
                                        Sınav başladığında geri sayım otomatk aktif olacaktır.
                                    </li>
                                    <li className='warning-text'>
                                        Sınava başlamadan önce internet  bağlantınızın stabil olduğundan emin olunuz.
                                    </li>
                                    <li className='warning-text'>
                                        Sınava başladıktan sonra kesinlikle sayfayı yenilememeniz gerekir! 
                                        SAYFAYI YENİLEMENİZ HALİNDE SINAVINIZ SON BULACAKTIR!                                       
                                    </li>
                                    <li>
                                        Onayladıktan sonra sınav sürecini başlatmaktan sorumlusunuz.
                                    </li>
                                    <li className='warning-text'>
                                        Her kullanıcının sınava bir kez katılma hakkı vardır!
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>

                    <p className='rules-approve-text'>Metni onayladıktan sonra Sınavı Başlat butonuna tılayarak yukarıdaki kuralları kabul ettiğinizi ve sınav sürecine başlamak istediğinizi belirtmiş olacaksınız.</p>

                </div>

                <div className="form-check mb-3">
                    <input
                        className="rules-check-input"
                        type="checkbox"
                        id="acceptRules"
                        checked={accepted}
                        onChange={handleAccept}
                    />
                    <label className="form-check-label form-check-label-text" htmlFor="acceptRules">
                        Onaylıyorum
                    </label>
                </div>


                <div className="text-center">
                    <button onClick={startExam} className="btn start-exam-btn-primary">
                        SINAVI BAŞLAT
                    </button>
                </div>
            </div>

            {showModal && <InfoModal
                show={showModal}
                handleClose={handleCloseModal}
                title={modalTitle}
                body={modalBody}
            />}

        </div>
    )

}


