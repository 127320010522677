import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class ExamRecordService {

  startExam() {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/exam-records/start`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }

  updateExamRecord(id, updateDatas) {

    return axios({
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/exam-records/with-id/${id}`,
      data: updateDatas,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

    })
  }

  getExamRecordsByUserId(userId) {

    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/exam-records/userId/${userId}`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }

  sendWarningExitFullScreenMailToAdmin() {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/exam-records/warning-exit-fullscreen-mail`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }


}
