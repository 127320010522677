import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class QuestionService {

    addQuestion(data) {
     
        return axios({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
            data: data
        })
    }

    getQuestionByUserId(userId) {

        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions/with-userId/${userId}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    getFAQuestions() {

        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions/with-faq`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    deleteQuestionByUser(id) {

        return axios({
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions/by-user/${id}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    getAllQuestion() {

        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    updateQuestion(questionId,data) {
     
        return axios({
            method: 'put',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions/${questionId}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
            data: data
        })
    }

    deleteQuestionByAdmin(id) {

        return axios({
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/questions/by-admin/${id}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }
}