import React from 'react';
import '../styles/Communication.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


export default function Communication() {
    return (
        <div className="communication-container">

            <div className='communication-text-div'>
                <p>İletişime Geç</p>
                <p><FontAwesomeIcon icon={faMapLocationDot} style={{ color: "black", marginRight: "5%" }} />Zafer Mah, 177. Sk. No:2, 34513 Esenyurt/İstanbul</p>
                <p><FontAwesomeIcon icon={faEnvelope} style={{ color: "black", marginRight: "5%" }} />destek@sunnyteknoloji.com.tr</p>
                <p><FontAwesomeIcon icon={faPhone} style={{ color: "black", marginRight: "10%" }} />0212 412 12 12</p>
            </div>
            <div className='communication-map-div'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.5134879113107!2d28.681067249956985!3d41.01402061907046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa09b935f640d%3A0xc13072df25f34409!2zU1VOTlkgRUxFS1RST07EsEsgU0FOQVnEsCBWRSBUxLBDQVJFVCBBLsWeLg!5e0!3m2!1str!2str!4v1725537122528!5m2!1str!2str"
                    style={{ width: '100%', height: '100%', border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}
