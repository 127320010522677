import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class TeamService {
  
    addTeam(data) {
  
      return axios({
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/teams`,
        data: data   
  
      })
    }

    getTeamByUserId() {
  
      return axios({
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/teams`,  
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

      })
    }

    updateTeam(debugUserId,data) {
  
      return axios({
        method: 'put',
        maxBodyLength: Infinity,
        url: `${baseURL}/api/teams/${debugUserId}`,
        data: data,
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

  
      })
    }
}