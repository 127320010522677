import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class UsersService {

  getAllUsers() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }

  getAllUsersInfoResponse() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users/info-response`,
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
    })
  }

  addUser(data) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data', // FormData gönderdiğimi belirtmek için
      }

    })
  }

  login(data) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/auth/login`,
      data: data
    })
  }

  getUserWithUsernameOrEmail(usernameOrEmail) { 

    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users/with-usernameOrEmail/${usernameOrEmail}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') }
    })
  }
  
  sendVerificationCode(email) { 

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users/send-verification-code?email=${email}`,
      //headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') }
    })
  }

  updateUserPassword(updateDatas) { 

    return axios({
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users/update-password`,
      //headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') },
      data: updateDatas
    })
  }

  deleteAllUserRecords(userId) { 

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/users/${userId}`,
      headers: { 'Authorization': 'Bearer '  + sessionStorage.getItem('token') },
    })
  }

}
