import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/DarkTable.css';
import '../../styles/ExamResult.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExamRecordService from '../../services/ExamRecordService';
import UsersService from '../../services/UsersService';

export default function ExamResult() {

    const navigate = useNavigate();
    const examRecordService = new ExamRecordService()
    const usersService = new UsersService()

    const [allUsers, setAllUsers] = useState([])

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const [infoFormData, setInfoFormData] = useState({
        id: '',
        name: '',
        institutionName: '',
        city: '',
        examRecords: [],
        totalTimeTaken: ''
    });

    const [searchTerm, setSearchTerm] = useState("");

    const filteredUsers = allUsers.filter((user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInfo = (id) => {
        // info modalını açma

        setSelectedUserId(id);

        examRecordService.getExamRecordsByUserId(id).then(result => {

            if (result.data.success) {

                const examRecords = result.data.data.examRecords;
                let totalTimeTaken = 0;

                // Calculate times for each question
                for (let i = 0; i < examRecords.length; i++) {
                    const currentRecord = examRecords[i];
                    let startTime = currentRecord.startTime ? new Date(currentRecord.startTime) : null;
                    const finishTime = new Date(currentRecord.finishTime);

                    // If startTime is null, use the previous question's finishTime
                    if (i > 0 && startTime === null) {
                        startTime = new Date(examRecords[i - 1].finishTime);
                    }

                    const timeTaken = startTime ? (finishTime - startTime) / 1000 : 0; // Time in seconds
                    currentRecord.timeTaken = timeTaken; // Add timeTaken to record
                    totalTimeTaken += timeTaken;

                }

                setInfoFormData({
                    id: result.data.data.user.id,
                    name: result.data.data.user.firstName + ' ' + result.data.data.user.lastName,
                    institutionName: result.data.data.user.institution.name,
                    city: result.data.data.user.city.name,
                    examRecords: examRecords,
                    totalTimeTaken: totalTimeTaken
                });

                setShowInfoModal(true);
            }

        }).catch(error => {
            toast.error("Kullanıcı sınav sonuçlarını çekerken bir sorun oluştu!");
        })
    };

    const handleCloseInfoModal = () => {
        // Info modalını kapama

        setShowInfoModal(false);
        setSelectedUserId(null);
        setInfoFormData({
            id: '',
            name: '',
            institutionName: '',
            city: '',
            examRecords: [],
            totalTimeTaken: ''
        });
    };

    useEffect(() => {
        usersService.getAllUsersInfoResponse().then(result => {
            if (result.data.success) {
                setAllUsers(result.data.data);
            }
        }).catch(error => {
            toast.error("Tüm kullanıcılar listelenirken bir hata oluştu!");
        })
    }, []);

    const autoResize = (e) => {
        e.target.style.height = "auto"; // İlk başta yüksekliği sıfırla
        e.target.style.height = `${e.target.scrollHeight}px`; // İçerik yüksekliğine göre ayarla
    };

    return (
        <div className="dark-tb-main">
            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">Sınav Sonuçları</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col" colSpan="6">
                                <div className="search-container">
                                    <input
                                        type="text"
                                        placeholder="Kullanıcıları ara..."
                                        className="search-input"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color dark-tb-body-text" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">OKULU</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">DETAYLI BİLGİ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={user.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{user.firstName} {user.lastName}</td>
                                <td className="dark-tb-body-text">{user.institutionName}</td>
                                <td>
                                    <button
                                        className="btn btn-sm btn-info me-2"
                                        onClick={() => handleInfo(user.id)}
                                    >
                                        <i className="fas fa-info-circle"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Bilgi Modal */}
            <Modal show={showInfoModal} onHide={handleCloseInfoModal} dialogClassName="exam-result-custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Sınav Sonuçları</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={infoFormData.name}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Okulu</Form.Label>
                            <Form.Control
                                type="text"
                                name="institutionName"
                                value={infoFormData.institutionName}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Şehir</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={infoFormData.city}
                                readOnly
                            />
                        </Form.Group>

                        {infoFormData.examRecords.map((examRecord, index) => (
                            <Form.Group className="mb-3" key={index}>
                                <Form.Label>{examRecord.examQuestion.id}. Soru</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    name="question"
                                    onInput={(e) => autoResize(e)}
                                    style={{ overflow: "hidden" }}
                                    value={examRecord.examQuestion.question}
                                    readOnly
                                />
                                <Form.Label>Cevap</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    name="userResponse"
                                    rows={3}
                                    onInput={(e) => autoResize(e)}
                                    style={{ overflow: "hidden" }}
                                    value={examRecord.userResponse}
                                    readOnly
                                />
                                <Form.Label style={{ color: "blue" }}>
                                    Süre: {" "}
                                    {Math.floor((examRecord.timeTaken < 0 ? 0 : examRecord.timeTaken) / 60)} dk
                                    {" "}
                                    {(examRecord.timeTaken < 0 ? 0 : examRecord.timeTaken) % 60} sn
                                </Form.Label>
                            </Form.Group>
                        ))}

                        <Form.Group className="mb-3">
                            <Form.Label style={{ color: "green" }}>
                                Toplam Süre: {" "}
                                {Math.floor((infoFormData.totalTimeTaken < 0 ? 0 : infoFormData.totalTimeTaken) / 60)} dk
                                {" "}
                                {(infoFormData.totalTimeTaken < 0 ? 0 : infoFormData.totalTimeTaken) % 60} sn
                            </Form.Label>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInfoModal}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
