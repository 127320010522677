import React from 'react'

export default function Organization() {
  return (
    <div className='organization-container main'>
      Organization



      <style jsx>
        {`
          .organization-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: blue;
            margin-top: 2%;
            margin-bottom: 2%;
          }

      `}
      </style>
    </div>
  )
}
